.App-logo {
    height: 150px;
    width: 300px;
    pointer-events: none;
  }

.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #10223c;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
  }

  .search-bar input {
    width: 350px;
    padding: 5px;
    border-radius: 5px;
    border: none;
  }
  
  .top-bar-right {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }


  /* Responsive Design */
@media (max-width: 768px) {
  .top-bar {
    flex-direction: column;
    align-items: flex-start;
  }

  .App-logo {
    height: 75px;
    width: 150px;
  }

  .search-bar {
    width: 100%;
    margin: 10px 0;
  }

  .search-bar input {
    width: 100%;
  }

  .top-bar-right {
    width: 100%;
  }
}
