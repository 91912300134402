/* src/components/Menu.css */
.menu-container {
    position: relative;
  }
  
  .menu-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .menu-content {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
  }
  
  .menu-content ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-content li {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
  }
  
  .menu-content li:last-child {
    border-bottom: none;
  }
  
  .menu-content a {
    text-decoration: none;
    color: black;
  }
  
  .menu-content a:hover {
    background-color: #f1f1f1;
  }
  