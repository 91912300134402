

/* src/components/Homepage.css */
.homepage-container {
  font-family: Arial, sans-serif;
}


.theaters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  flex-direction: column;
  align-items: center;
}

.theater-card {
  width: 400px;
  margin: 20px;
  text-align: center;
  text-decoration: none;
  color: rgb(186, 164, 164);
}

.theater-card img {
  width: 100%;
  border-radius: 10px;
}

.theater-name {
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
}


  /* Responsive Design */
  @media (max-width: 768px) {
    .container {
      padding: 9px;
    }
    .theater-card {
      width: 60%;
    }
  }