/* src/components/SeatingArrangement.css */
.seating-arrangement {
  text-align: center;
  padding: 20px;
}

.seating-arrangement-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  overflow-x:auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent line breaks */
  width: 100%;
}

.section {
  margin-bottom: 35px;
  text-align: center;
  display: inline-block; /* Allow horizontal alignment */
  width: 100%; /* Ensure section takes full width */
}
.section-head {
  font-size: 30px;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.price {
  font-size: 20px;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.rows {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.row-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}

.row-name {
  width: 40px; /* Adjust based on your needs */
  height: 20px;
  display: flex;
  position: sticky;
  left: 0;
  background-color: #fff; /* Match your background color */
  z-index: 1;
  border-right: 1px solid #ccc; /* Optional: to visually separate from the seats */
  padding: 3px; /* Adjust based on your needs */
  text-align: center;
  justify-content: center;
  align-items: center;
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Align seats to the start */
  margin-left: 30px;
  margin-bottom: 5px;
}


.gap {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.seats-container {
  
  justify-content: center;
}

.seat {
  width: 25px;
  height: 25px;
  margin: 3px;
  background-color: #ddd;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.seat-text {
  user-select: none; /* Prevent text selection */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.seat.available {
  background-color: #4caf50;
}

.seat.selected {
  background-color: #ffeb3b;
}

.seat.booked {
  background-color: #f44336;
  cursor: not-allowed;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #bbb;
  cursor: not-allowed;
}

.seat-legends-wrapper {
  height: 32px;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  z-index: 98;
}

.available-seat-status {
  width: 20px;
  height: 20px;
  margin: 3px;
  background-color: #4caf50;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-seat-status {
  width: 20px;
  height: 20px;
  margin: 3px;
  background-color: #ffeb3b;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sold-seat-status {
  width: 20px;
  height: 20px;
  margin: 3px;
  background-color: #f44336;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentbutton {
  display: flex;
  justify-content: center;
  background: #fff;
  padding: 10px;
  text-align: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
.button1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 49%;
  height: 100%;
  box-shadow: 0 1px 8px rgba(0, 0, 0, .16);
  border-radius: 8px;
  cursor: pointer;
  min-height: 40px;
  max-width: 400px;
}
/*Loading animation */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  color: white;
  font-size: 1.5rem;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin-right: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
