.list-your-show {
    background-color: rgb(49, 48, 53);
    color: rgb(255, 255, 255);
    font-size: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 15px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
    
}
.got-a-show {
    background: #0000;
    border: 0;
    font-size: 100%;
    
}
.contact {
    flex: 0 0 auto;
    background-color: rgb(236, 94, 113);
    border-radius: 3px;
    padding: 10px 20px;
    cursor: pointer;
    text-decoration: none;
    color: rgb(255, 255, 255);
}

.support {
    display: flex;
    
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    overflow: visible;
    background-color: rgb(64, 64, 67);
    padding: 15px;
    color: rgb(153, 153, 153);
    font-weight: 400;
}
.svgs {
    display: inline-block;
    width: 50px;
    height: 50px;
}

a {
    background: #0000;
    font-size: 100%;
    margin: 0;
    padding: 0;
    text-decoration: none;
    vertical-align: initial;
}

.helpSectionContainer {
    padding-top: 25px;
    background: rgb(64, 64, 67);
    border: 0;
    font-size: 100%;
    font-weight: 400;
    margin: 0;
    vertical-align: initial;
    color: rgb(153, 153, 153);
    align-items: center;
}
.helpSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.helpHeader {
    font-size: 12px;
    line-height: 1.1;
    font-weight: 400;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: rgb(165, 165, 165);
}
.helpLinks {
    font-size: 11px;
    color: rgb(127, 127, 127);
    letter-spacing: 0.1px;
    text-decoration: none;
    transition: color 0.25s ease-in-out 0s;
    margin: 0px 5px 5px 0px;
    padding-right: 5px;
    border-right: 1px solid rgb(127, 127, 127);
}
.helpLinksLast {
    font-size: 11px;
    color: rgb(127, 127, 127);
    letter-spacing: 0.1px;
    text-decoration: none;
    transition: color 0.25s ease-in-out 0s;
    margin: 0px 5px 5px 0px;
    padding-right: 5px;
}

.logoholder {
    display: flex;
    flex-direction: row;
    background-color: rgb(57, 57, 60);
    justify-content: space-evenly;
    align-items: center;
}
.logo {
    padding-right: 10px;
    padding-left: 10px;
    width: 200px;
    height: 100px;
}
.linkstofollow {
    display: flex;
    flex-direction: row;
    background-color: rgb(57, 57, 60);
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
}
.links {
    width: 37px;
    height: 37px;
    padding: 5px;
}
.lastblock {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    font-size: 13px;
    background-color: rgb(57, 57, 60);
    color: rgb(102, 102, 102);
}


@media (max-width: 768px) {
    .list-your-show{
        font-size: 12px;
    }
    .contact{
        padding: 5px 10px;
    }
    .svgs{
        width: 20px;
        height: 20px;
    }
    .texts{
        font-size: 10px;
    }
    .logo{
        width: 150px;
        height: 75px;
    }
    .links{
        width: 20px;
        height: 20px;
    }
    .lastblock{
        padding: 20px;
        font-size: 11px;
        margin-bottom: 50px;

    }
}