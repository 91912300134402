/* src/components/MyOrders.css */
.my-orders {
    padding: 20px;
  }
  
  .my-orders h1 {
    margin-bottom: 20px;
  }
  
  .tickets-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .ticket-card {
    align-items: center;
    border: 1px solid #ccc;
    margin: 5px;
    padding: 0;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  .ticket-card p {
    margin: 2px 0; /* Adjust margin to decrease spacing */
    padding: 0;    /* Remove padding if any */
  }

  .ticket-card h4 {
    margin: 4px 0;
    padding-bottom: 5px;
  }
  
  
  .download-button {
    align-self: center;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .qrcode {
    align-self: center;
    padding-bottom: 5px;
  }