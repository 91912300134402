.contain {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    flex-direction: column; /* Stack items vertically */
    padding: 0 40px 40px;
}

.google-button {
    position: relative;
    width: 320px;
    height: 52px;
    border: 1px solid #c2c8d0;
    border-radius: 6px;
    background-color: #fff;
    cursor: pointer;
    color: #2d333a;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start; /* Align items to the start (left) */
    align-items: center; /* Center vertically */
    padding: 0 20px; /* Adjust padding */
}

.google-wrapper {
    width: 40px; /* Adjust width */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.google-logo {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px; /* Add margin for spacing */
}

.social-text {
    flex: 1; /* Take remaining space */
    text-align: left;
    color: #2d333a;
    font-size: 16px;
}

.showsContainer {
    display: flex;
    justify-content: space-evenly;
}
.shows {
    width: 100px;
    height: 150px;
}

@media (max-width: 768px) {
    .ytvids {
        width: 70%;
        height: 20%;
    }

}