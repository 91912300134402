.bookings-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .bookings-table th, .bookings-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .bookings-table th {
    background-color: #f4f4f4;
    font-weight: bold;
  }
  
  .bookings-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .bookings-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .bookings-table th.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f4f4f4;
    z-index: 1;
  }
  
  .bookings-table td {
    font-size: 0.9em;
    color: #333;
  }
  
  .bookings-table tbody tr {
    transition: background-color 0.2s;
  }
  
  .bookings-table tbody tr:hover {
    background-color: #e0e0e0;
  }
  
  .bookings-table td, .bookings-table th {
    border-right: 1px solid #ddd;
  }
  
  .bookings-table th:last-child, .bookings-table td:last-child {
    border-right: none;
  }
  
  .bookings-table tbody tr:last-child td {
    border-bottom: none;
  }
  
  @media (max-width: 768px) {
    .stats {
      flex-direction: column;
    }
  
    .stat-box {
      width: 100%;
      margin-bottom: 20px;
    }
  
    .controls {
      flex-direction: column;
      align-items: center;
    }
  
    .search-bar {
      margin-bottom: 15px;
      width: 100%;
    }
  
    .download-btn {
      width: 100%;
    }
  }
  