/* src/components/TheatrePage.css */
.container {
  padding: 9px;
}
.theatre-page {
    text-align: center;
    padding: 20px;
  }
  
  .shows-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
  }
  
  .show-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 5px;
    width: calc(50% - 10px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .show-card img {
    max-width: 100%;
    height: 300px;
    border-radius: 5px;
  }
  
  .show-card h3 {
    margin-top: 0;
  }
  
  .show-card p {
    margin: 10px 0;
  }
  
  .show-card a {
    display: inline-block;
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .show-card a:hover {
    background-color: #0056b3;
  }


  @media (max-width: 768px) {
    .show-card {
      border: 1px solid #ddd;
      padding: 15px;
      border-radius: 5px;
      width: calc(70% - 10px);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }
    .show-card img {
      height: 200px;
    }
  }