.profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: #f5f5f5;
    min-height: 100vh;
  }
  
  .profile-header {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .profile-name {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .profile-email {
    font-size: 16px;
    color: #888;
  }
  
  .logout-button {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  
  .profile-links {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .profile-links a {
    color: #2196f3;
    text-decoration: none;
    margin: 10px 0;
  }
  
  .profile-links a:hover {
    text-decoration: underline;
  }
  